export default {
  headers: [
    {
      id: 'top-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/lopud/logo.png',
        url: 'https://www.flagstaffhouses.com/',
      },
      breakpoint: 915,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-lopud-top-header-logo-container',
          imageClassName: '',
        },
        headerClassName: 'on-lopud-top-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: 'on-lopud-top-header-section',
        itemClassName: 'on-lopud-top-header-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'on-lopud-top-header-phone-item',
            content: '(928) 214-7100',
          },
          {
            id: 'on-lopud-top-header-socials',
            menuItems: [
              {
                id: 'on-lopud-top-header-fb-item',
                iconSet: 'fab',
                className: 'facebook',
                url: 'https://www.facebook.com/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-ig-item',
                iconSet: 'fab',
                className: 'instagram',
                url: 'https://www.instagram.com/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-linkedin-item',
                iconSet: 'fab',
                className: 'linkedin',
                url: 'https://www.linkedin.com/in/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-link-item',
                iconSet: 'fa',
                className: 'link',
                url: 'https://www.zillow.com/profile/flagstaffhouses/',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-lopud-top-mheader-logo-container',
          imageClassName: 'on-lopud-top-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-lopud-top-mheader',
        headerSectionsClassName: 'on-lopud-top-mheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-lopud-top-mheader-menu',
        itemClassName: 'on-lopud-top-mheader-item',
        menuClassName: 'on-lopud-top-mheader-folder',
        menuLabelActiveClassName: 'on-lopud-top-mheader-active',
        menuItemClassName: 'on-lopud-top-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'on-lopud-top-header-phone-item',
            content: '(928) 214-7100',
          },
          {
            id: 'on-lopud-top-header-socials',
            menuItems: [
              {
                id: 'on-lopud-top-header-fb-item',
                iconSet: 'fab',
                className: 'facebook',
                url: 'https://www.facebook.com/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-ig-item',
                iconSet: 'fab',
                className: 'instagram',
                url: 'https://www.instagram.com/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-linkedin-item',
                iconSet: 'fab',
                className: 'linkedin',
                url: 'https://www.linkedin.com/in/flagstaffhouses/',
              },
              {
                id: 'on-lopud-top-header-link-item',
                iconSet: 'fa',
                className: 'link',
                url: 'https://www.zillow.com/profile/flagstaffhouses/',
              },
            ],
          },
        ],
        items: [
          {
            id: 'desktop-item-home',
            content: 'Home',
            url: 'https://www.flagstaffhouses.com/',
          },
          {
            id: 'desktop-item-about',
            content: 'About',
            menuItems: [
              {
                id: 'desktop-submenu-item-meet-team',
                content: 'Meet the Team',
                url: 'https://www.flagstaffhouses.com/our-agents/',
              },
              {
                id: 'desktop-submenu-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.flagstaffhouses.com/testimonials',
              },
              {
                id: 'desktop-submenu-item-listings',
                content: 'Featured Listings',
                url: 'http://search.flagstaffhouses.com/idx/featured',
              },
            ],
          },
          {
            id: 'desktop-item-search',
            content: 'Search',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-all',
                content: 'Search All Homes',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced?Headline=Search%20All%20Homes%20and%20Real%20Estate',
              },
              {
                id: 'desktop-submenu-item-listing-updates',
                content: 'Receive Daily Email Listing Updates',
                url: 'http://search.flagstaffhouses.com/idx/search/emailupdatesignup?Headline=Receive%20Daily%20Listing%20Email%20Updates',
              },
              {
                id: 'desktop-submenu-item-listing-organizer',
                content: 'Create Your Own Saved Listing Organizer',
                url: 'http://search.flagstaffhouses.com/idx/usersignup?Headline=Create%20Your%20Own%20Saved%20Listing%20Organizer',
              },
              {
                id: 'desktop-submenu-item-organizer-login',
                content: 'Saved Listing Organizer Login',
                url: 'http://search.flagstaffhouses.com/idx/userlogin?Headline=Saved%20Listing%20Organizer%20Login',
              },
              {
                id: 'desktop-submenu-item-featured-home',
                content: 'View Our Featured Home For Sale',
                url: 'http://search.flagstaffhouses.com/idx/featured?Headline=View%20Our%20Featured%20Home%20For%20Sale',
              },
              {
                id: 'desktop-submenu-item-map-search',
                content: 'Map Search',
                url: 'http://search.flagstaffhouses.com/idx/map/mapsearch?Headline=Map%20Search',
              },
              {
                id: 'desktop-submenu-item-search-by-id',
                content: 'Search By Listing ID#',
                url: 'http://search.flagstaffhouses.com/idx/search/listingid?Headline=Search%20By%20Listing%20ID',
              },
              {
                id: 'desktop-submenu-item-search-by-address',
                content: 'Search By Address',
                url: 'http://search.flagstaffhouses.com/idx/search/address?Headline=Search%20By%20Address',
              },
              {
                id: 'desktop-submenu-item-search-by-city',
                content: 'Search By City',
                url: 'http://search.flagstaffhouses.com/idx/searchbycity?Headline=Search%20By%20City',
              },
              {
                id: 'desktop-submenu-item-gallery',
                content: 'Sold/Pending Gallery',
                url: 'https://search.flagstaffhouses.com/idx/soldpending',
              },
            ],
          },
          {
            id: 'desktop-item-buyers',
            content: 'Buyers',
            menuItems: [
              {
                id: 'desktop-submenu-item-faqs',
                content: 'HUD Homes for Sale in Flagstaff FAQs',
                url: 'https://www.flagstaffhouses.com/hud-homes/',
              },
              {
                id: 'desktop-submenu-item-perfect-home',
                content: 'Find The Perfect Home',
                url: 'https://www.flagstaffhouses.com/find-the-perfect-home/',
              },
              {
                id: 'desktop-submenu-item-mortgage-calculator',
                content: 'Mortgage Calculator',
                url: 'http://search.flagstaffhouses.com/idx/mortgage',
              },
              {
                id: 'desktop-submenu-item-relocating',
                content: 'Relocating to Flagstaff',
                url: 'https://www.flagstaffhouses.com/relocating/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Cash Offer',
                url: 'http://cashoffer.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-sellers',
            content: 'Sellers',
            menuItems: [
              {
                id: 'desktop-submenu-item-home-online',
                content: 'Feature/Market Your Home Online',
                url: 'https://www.flagstaffhouses.com/featuremarket-your-home-online/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Free Home Estimate',
                url: 'https://search.flagstaffhouses.com/idx/homevaluation',
              },
              {
                id: 'desktop-submenu-item-ask-question',
                content: 'Ask Us A Question',
                url: 'https://www.flagstaffhouses.com/ask-us-a-question/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Trade In',
                url: 'http://trade-in.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-communities',
            content: 'Communities',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-advanced',
                content: 'Search All Cities',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced',
              },
              {
                id: 'desktop-submenu-item-amberwood',
                content: 'Amberwood',
                url: 'https://www.flagstaffhouses.com/community/amberwood-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-country-club',
                content: 'Country Club',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-country-club-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-downtown-flagstaff',
                content: 'Downtown Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/downtown-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff',
                content: 'Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff-ranch',
                content: 'Flagstaff Ranch',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-ranch-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-linwood-heights',
                content: 'Linwood Heights',
                url: 'https://www.flagstaffhouses.com/community/linwood-heights-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-lockett-ranches',
                content: 'Lockett Ranches',
                url: 'https://www.flagstaffhouses.com/community/lockett-ranches-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-pine-canyon',
                content: 'Pine Canyon',
                url: 'https://www.flagstaffhouses.com/community/pine-canyon-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-ponderosa-trails',
                content: 'Ponderosa Trails',
                url: 'https://www.flagstaffhouses.com/community/ponderosa-trails-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-university-heights-highlands',
                content: 'University Heights/ Highlands',
                url: 'https://www.flagstaffhouses.com/community/university-heights-highlands-az-real-estate/',
              },
            ],
          },
          {
            id: 'desktop-item-contact',
            content: 'Contact',
            url: 'https://www.flagstaffhouses.com/contact/',
          },
          {
            id: 'desktop-item-sales-history',
            content: 'Sales History',
            url: 'https://www.flagstaffhouses.com/past-sales/',
          },
        ],
      },
    },
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/otok/logo.png',
        url: '',
      },
      breakpoint: 915,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-lopud-header',
        headerSectionsClassName: 'on-lopud-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-lopud-header-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-lopud-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'desktop-item-home',
            content: 'HOME',
            url: 'https://www.flagstaffhouses.com/',
          },
          {
            id: 'desktop-item-about',
            content: 'ABOUT',
            menuItems: [
              {
                id: 'desktop-submenu-item-meet-team',
                content: 'Meet the Team',
                url: 'https://www.flagstaffhouses.com/our-agents/',
              },
              {
                id: 'desktop-submenu-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.flagstaffhouses.com/testimonials',
              },
              {
                id: 'desktop-submenu-item-listings',
                content: 'Featured Listings',
                url: 'http://search.flagstaffhouses.com/idx/featured',
              },
            ],
          },
          {
            id: 'desktop-item-search',
            content: 'SEARCH',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-all',
                content: 'Search All Homes',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced?Headline=Search%20All%20Homes%20and%20Real%20Estate',
              },
              {
                id: 'desktop-submenu-item-listing-updates',
                content: 'Receive Daily Email Listing Updates',
                url: 'http://search.flagstaffhouses.com/idx/search/emailupdatesignup?Headline=Receive%20Daily%20Listing%20Email%20Updates',
              },
              {
                id: 'desktop-submenu-item-listing-organizer',
                content: 'Create Your Own Saved Listing Organizer',
                url: 'http://search.flagstaffhouses.com/idx/usersignup?Headline=Create%20Your%20Own%20Saved%20Listing%20Organizer',
              },
              {
                id: 'desktop-submenu-item-organizer-login',
                content: 'Saved Listing Organizer Login',
                url: 'http://search.flagstaffhouses.com/idx/userlogin?Headline=Saved%20Listing%20Organizer%20Login',
              },
              {
                id: 'desktop-submenu-item-featured-home',
                content: 'View Our Featured Home For Sale',
                url: 'http://search.flagstaffhouses.com/idx/featured?Headline=View%20Our%20Featured%20Home%20For%20Sale',
              },
              {
                id: 'desktop-submenu-item-map-search',
                content: 'Map Search',
                url: 'http://search.flagstaffhouses.com/idx/map/mapsearch?Headline=Map%20Search',
              },
              {
                id: 'desktop-submenu-item-search-by-id',
                content: 'Search By Listing ID#',
                url: 'http://search.flagstaffhouses.com/idx/search/listingid?Headline=Search%20By%20Listing%20ID',
              },
              {
                id: 'desktop-submenu-item-search-by-address',
                content: 'Search By Address',
                url: 'http://search.flagstaffhouses.com/idx/search/address?Headline=Search%20By%20Address',
              },
              {
                id: 'desktop-submenu-item-search-by-city',
                content: 'Search By City',
                url: 'http://search.flagstaffhouses.com/idx/searchbycity?Headline=Search%20By%20City',
              },
              {
                id: 'desktop-submenu-item-gallery',
                content: 'Sold/Pending Gallery',
                url: 'https://search.flagstaffhouses.com/idx/soldpending',
              },
            ],
          },
          {
            id: 'desktop-item-buyers',
            content: 'BUYERS',
            menuItems: [
              {
                id: 'desktop-submenu-item-faqs',
                content: 'HUD Homes for Sale in Flagstaff FAQs',
                url: 'https://www.flagstaffhouses.com/hud-homes/',
              },
              {
                id: 'desktop-submenu-item-perfect-home',
                content: 'Find The Perfect Home',
                url: 'https://www.flagstaffhouses.com/find-the-perfect-home/',
              },
              {
                id: 'desktop-submenu-item-mortgage-calculator',
                content: 'Mortgage Calculator',
                url: 'http://search.flagstaffhouses.com/idx/mortgage',
              },
              {
                id: 'desktop-submenu-item-relocating',
                content: 'Relocating to Flagstaff',
                url: 'https://www.flagstaffhouses.com/relocating/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Cash Offer',
                url: 'http://cashoffer.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-sellers',
            content: 'SELLERS',
            menuItems: [
              {
                id: 'desktop-submenu-item-home-online',
                content: 'Feature/Market Your Home Online',
                url: 'https://www.flagstaffhouses.com/featuremarket-your-home-online/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Free Home Estimate',
                url: 'https://search.flagstaffhouses.com/idx/homevaluation',
              },
              {
                id: 'desktop-submenu-item-ask-question',
                content: 'Ask Us A Question',
                url: 'https://www.flagstaffhouses.com/ask-us-a-question/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Trade In',
                url: 'http://trade-in.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-communities',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-advanced',
                content: 'Search All Cities',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced',
              },
              {
                id: 'desktop-submenu-item-amberwood',
                content: 'Amberwood',
                url: 'https://www.flagstaffhouses.com/community/amberwood-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-country-club',
                content: 'Country Club',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-country-club-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-downtown-flagstaff',
                content: 'Downtown Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/downtown-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff',
                content: 'Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff-ranch',
                content: 'Flagstaff Ranch',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-ranch-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-linwood-heights',
                content: 'Linwood Heights',
                url: 'https://www.flagstaffhouses.com/community/linwood-heights-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-lockett-ranches',
                content: 'Lockett Ranches',
                url: 'https://www.flagstaffhouses.com/community/lockett-ranches-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-pine-canyon',
                content: 'Pine Canyon',
                url: 'https://www.flagstaffhouses.com/community/pine-canyon-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-ponderosa-trails',
                content: 'Ponderosa Trails',
                url: 'https://www.flagstaffhouses.com/community/ponderosa-trails-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-university-heights-highlands',
                content: 'University Heights/ Highlands',
                url: 'https://www.flagstaffhouses.com/community/university-heights-highlands-az-real-estate/',
              },
            ],
          },
          {
            id: 'desktop-item-contact',
            content: 'CONTACT',
            url: 'https://www.flagstaffhouses.com/contact/',
          },
          {
            id: 'desktop-item-sales-history',
            content: 'SALES HISTORY',
            url: 'https://www.flagstaffhouses.com/past-sales/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-reversed', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-lopud-hamburger',
          hide: false,
          reverse: true,
        },
        headerClassName: 'on-lopud-mheader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-lopud-mheader-menu',
        itemClassName: 'on-lopud-mheader-item',
        menuClassName: 'on-lopud-mheader-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-lopud-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'desktop-item-home',
            content: 'HOME',
            url: 'https://www.flagstaffhouses.com/',
          },
          {
            id: 'desktop-item-about',
            content: 'ABOUT',
            url: 'https://www.flagstaffhouses.com/our-agents/',
            menuItems: [
              {
                id: 'desktop-submenu-item-meet-team',
                content: 'Meet the Team',
                url: 'https://www.flagstaffhouses.com/our-agents/',
              },
              {
                id: 'desktop-submenu-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.flagstaffhouses.com/testimonials',
              },
              {
                id: 'desktop-submenu-item-listings',
                content: 'Featured Listings',
                url: 'http://search.flagstaffhouses.com/idx/featured',
              },
            ],
          },
          {
            id: 'desktop-item-search',
            content: 'SEARCH',
            url: 'http://search.flagstaffhouses.com/idx/search/advanced?Headline=Search%20All%20Homes%20and%20Real%20Estate',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-all',
                content: 'Search All Homes',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced?Headline=Search%20All%20Homes%20and%20Real%20Estate',
              },
              {
                id: 'desktop-submenu-item-listing-updates',
                content: 'Receive Daily Email Listing Updates',
                url: 'http://search.flagstaffhouses.com/idx/search/emailupdatesignup?Headline=Receive%20Daily%20Listing%20Email%20Updates',
              },
              {
                id: 'desktop-submenu-item-listing-organizer',
                content: 'Create Your Own Saved Listing Organizer',
                url: 'http://search.flagstaffhouses.com/idx/usersignup?Headline=Create%20Your%20Own%20Saved%20Listing%20Organizer',
              },
              {
                id: 'desktop-submenu-item-organizer-login',
                content: 'Saved Listing Organizer Login',
                url: 'http://search.flagstaffhouses.com/idx/userlogin?Headline=Saved%20Listing%20Organizer%20Login',
              },
              {
                id: 'desktop-submenu-item-featured-home',
                content: 'View Our Featured Home For Sale',
                url: 'http://search.flagstaffhouses.com/idx/featured?Headline=View%20Our%20Featured%20Home%20For%20Sale',
              },
              {
                id: 'desktop-submenu-item-map-search',
                content: 'Map Search',
                url: 'http://search.flagstaffhouses.com/idx/map/mapsearch?Headline=Map%20Search',
              },
              {
                id: 'desktop-submenu-item-search-by-id',
                content: 'Search By Listing ID#',
                url: 'http://search.flagstaffhouses.com/idx/search/listingid?Headline=Search%20By%20Listing%20ID',
              },
              {
                id: 'desktop-submenu-item-search-by-address',
                content: 'Search By Address',
                url: 'http://search.flagstaffhouses.com/idx/search/address?Headline=Search%20By%20Address',
              },
              {
                id: 'desktop-submenu-item-search-by-city',
                content: 'Search By City',
                url: 'http://search.flagstaffhouses.com/idx/searchbycity?Headline=Search%20By%20City',
              },
              {
                id: 'desktop-submenu-item-gallery',
                content: 'Sold/Pending Gallery',
                url: 'https://search.flagstaffhouses.com/idx/soldpending',
              },
            ],
          },
          {
            id: 'desktop-item-buyers',
            content: 'BUYERS',
            url: 'https://www.flagstaffhouses.com/find-the-perfect-home/',
            menuItems: [
              {
                id: 'desktop-submenu-item-faqs',
                content: 'HUD Homes for Sale in Flagstaff FAQs',
                url: 'https://www.flagstaffhouses.com/hud-homes/',
              },
              {
                id: 'desktop-submenu-item-perfect-home',
                content: 'Find The Perfect Home',
                url: 'https://www.flagstaffhouses.com/find-the-perfect-home/',
              },
              {
                id: 'desktop-submenu-item-mortgage-calculator',
                content: 'Mortgage Calculator',
                url: 'http://search.flagstaffhouses.com/idx/mortgage',
              },
              {
                id: 'desktop-submenu-item-relocating',
                content: 'Relocating to Flagstaff',
                url: 'https://www.flagstaffhouses.com/relocating/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Cash Offer',
                url: 'http://cashoffer.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-sellers',
            content: 'SELLERS',
            url: 'http://search.flagstaffhouses.com/idx/homevaluation',
            menuItems: [
              {
                id: 'desktop-submenu-item-home-online',
                content: 'Feature/Market Your Home Online',
                url: 'https://www.flagstaffhouses.com/featuremarket-your-home-online/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Free Home Estimate',
                url: 'https://search.flagstaffhouses.com/idx/homevaluation',
              },
              {
                id: 'desktop-submenu-item-ask-question',
                content: 'Ask Us A Question',
                url: 'https://www.flagstaffhouses.com/ask-us-a-question/',
              },
              {
                id: 'desktop-submenu-item',
                content: 'Trade In',
                url: 'http://trade-in.flagstaffhouses.com',
              },
            ],
          },
          {
            id: 'desktop-item-communities',
            content: 'COMMUNITIES',
            url: 'https://www.flagstaffhouses.com/community',
            menuItems: [
              {
                id: 'desktop-submenu-item-search-advanced',
                content: 'Search All Cities',
                url: 'http://search.flagstaffhouses.com/idx/search/advanced',
              },
              {
                id: 'desktop-submenu-item-amberwood',
                content: 'Amberwood',
                url: 'https://www.flagstaffhouses.com/community/amberwood-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-country-club',
                content: 'Country Club',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-country-club-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-downtown-flagstaff',
                content: 'Downtown Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/downtown-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff',
                content: 'Flagstaff',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-flagstaff-ranch',
                content: 'Flagstaff Ranch',
                url: 'https://www.flagstaffhouses.com/community/flagstaff-ranch-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-linwood-heights',
                content: 'Linwood Heights',
                url: 'https://www.flagstaffhouses.com/community/linwood-heights-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-lockett-ranches',
                content: 'Lockett Ranches',
                url: 'https://www.flagstaffhouses.com/community/lockett-ranches-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-pine-canyon',
                content: 'Pine Canyon',
                url: 'https://www.flagstaffhouses.com/community/pine-canyon-flagstaff-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-ponderosa-trails',
                content: 'Ponderosa Trails',
                url: 'https://www.flagstaffhouses.com/community/ponderosa-trails-az-real-estate/',
              },
              {
                id: 'desktop-submenu-item-university-heights-highlands',
                content: 'University Heights/ Highlands',
                url: 'https://www.flagstaffhouses.com/community/university-heights-highlands-az-real-estate/',
              },
            ],
          },
          {
            id: 'desktop-item-contact',
            content: 'CONTACT',
            url: 'https://www.flagstaffhouses.com/contact/',
          },
          {
            id: 'desktop-item-sales-history',
            content: 'SALES HISTORY',
            url: 'https://www.flagstaffhouses.com/past-sales/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-lopud-footer-one',
      className: 'on-lopud-footer-section',
      sectionClassName: 'on-lopud-footer-section-container',
      columns: [
        {
          id: 'on-lopud-f-1-col-1',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-1-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'About',
            },
            {
              id: 'f-1-col-1-menu',
              className: 'on-lopud-footer-menu',
              type: 'menu',
              itemClassName: 'on-lopud-footer-menu-item',
              items: [
                {
                  id: 'desktop-submenu-item-meet-team',
                  data: 'Meet the Team',
                  url: 'https://www.flagstaffhouses.com/our-agents/',
                },
                {
                  id: 'desktop-submenu-item-testimonials',
                  data: 'Testimonials',
                  url: 'https://www.flagstaffhouses.com/testimonials',
                },
                {
                  id: 'desktop-submenu-item-listings',
                  data: 'Featured Listings',
                  url: 'http://search.flagstaffhouses.com/idx/featured',
                },
              ],
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-2',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-2-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'Search',
            },
            {
              id: 'f-1-col-2-menu',
              className: 'on-lopud-footer-menu',
              type: 'menu',
              itemClassName: 'on-lopud-footer-menu-item',
              items: [
                {
                  id: 'desktop-submenu-item-search-all',
                  data: 'Search All Homes',
                  url: 'http://search.flagstaffhouses.com/idx/search/advanced?Headline=Search%20All%20Homes%20and%20Real%20Estate',
                },
                {
                  id: 'desktop-submenu-item-listing-updates',
                  data: 'Receive Daily Email Listing Updates',
                  url: 'http://search.flagstaffhouses.com/idx/search/emailupdatesignup?Headline=Receive%20Daily%20Listing%20Email%20Updates',
                },
                {
                  id: 'desktop-submenu-item-listing-organizer',
                  data: 'Create Your Own Saved Listing Organizer',
                  url: 'http://search.flagstaffhouses.com/idx/usersignup?Headline=Create%20Your%20Own%20Saved%20Listing%20Organizer',
                },
                {
                  id: 'desktop-submenu-item-organizer-login',
                  data: 'Saved Listing Organizer Login',
                  url: 'http://search.flagstaffhouses.com/idx/userlogin?Headline=Saved%20Listing%20Organizer%20Login',
                },
                {
                  id: 'desktop-submenu-item-featured-home',
                  data: 'View Our Featured Home For Sale',
                  url: 'http://search.flagstaffhouses.com/idx/featured?Headline=View%20Our%20Featured%20Home%20For%20Sale',
                },
                {
                  id: 'desktop-submenu-item-map-search',
                  data: 'Map Search',
                  url: 'http://search.flagstaffhouses.com/idx/map/mapsearch?Headline=Map%20Search',
                },
                {
                  id: 'desktop-submenu-item-search-by-id',
                  data: 'Search By Listing ID#',
                  url: 'http://search.flagstaffhouses.com/idx/search/listingid?Headline=Search%20By%20Listing%20ID',
                },
                {
                  id: 'desktop-submenu-item-search-by-address',
                  data: 'Search By Address',
                  url: 'http://search.flagstaffhouses.com/idx/search/address?Headline=Search%20By%20Address',
                },
                {
                  id: 'desktop-submenu-item-search-by-city',
                  data: 'Search By City',
                  url: 'http://search.flagstaffhouses.com/idx/searchbycity?Headline=Search%20By%20City',
                },
                {
                  id: 'desktop-submenu-item-gallery',
                  data: 'Sold/Pending Gallery',
                  url: 'https://search.flagstaffhouses.com/idx/soldpending',
                },
              ],
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-3',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-3-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'Buyers',
            },
            {
              id: 'f-1-col-3-menu',
              className: 'on-lopud-footer-menu',
              type: 'menu',
              itemClassName: 'on-lopud-footer-menu-item',
              items: [
                {
                  id: 'desktop-submenu-item-faqs',
                  data: 'HUD Homes for Sale in Flagstaff FAQs',
                  url: 'https://www.flagstaffhouses.com/hud-homes/',
                },
                {
                  id: 'desktop-submenu-item-perfect-home',
                  data: 'Find The Perfect Home',
                  url: 'https://www.flagstaffhouses.com/find-the-perfect-home/',
                },
                {
                  id: 'desktop-submenu-item-mortgage-calculator',
                  data: 'Mortgage Calculator',
                  url: 'http://search.flagstaffhouses.com/idx/mortgage',
                },
                {
                  id: 'desktop-submenu-item-relocating',
                  data: 'Relocating to Flagstaff',
                  url: 'https://www.flagstaffhouses.com/relocating/',
                },
                {
                  id: 'desktop-submenu-item',
                  data: 'Cash Offer',
                  url: 'http://cashoffer.flagstaffhouses.com',
                },
              ],
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-4',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-4-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'Sellers',
            },
            {
              id: 'f-1-col-4-menu',
              className: 'on-lopud-footer-menu',
              type: 'menu',
              itemClassName: 'on-lopud-footer-menu-item',
              items: [
                {
                  id: 'desktop-submenu-item-home-online',
                  data: 'Feature/Market Your Home Online',
                  url: 'https://www.flagstaffhouses.com/featuremarket-your-home-online/',
                },
                {
                  id: 'desktop-submenu-item',
                  data: 'Free Home Estimate',
                  url: 'https://search.flagstaffhouses.com/idx/homevaluation',
                },
                {
                  id: 'desktop-submenu-item-ask-question',
                  data: 'Ask Us A Question',
                  url: 'https://www.flagstaffhouses.com/ask-us-a-question/',
                },
                {
                  id: 'desktop-submenu-item',
                  data: 'Trade In',
                  url: 'http://trade-in.flagstaffhouses.com',
                },
              ],
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-5',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-5-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'Communities',
            },
            {
              id: 'f-1-col-4-menu',
              className: 'on-lopud-footer-menu',
              type: 'menu',
              itemClassName: 'on-lopud-footer-menu-item',
              items: [
                {
                  id: 'desktop-submenu-item-search-advanced',
                  data: 'Search All Cities',
                  url: 'http://search.flagstaffhouses.com/idx/search/advanced',
                },
                {
                  id: 'desktop-submenu-item-amberwood',
                  data: 'Amberwood',
                  url: 'https://www.flagstaffhouses.com/community/amberwood-flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-country-club',
                  data: 'Country Club',
                  url: 'https://www.flagstaffhouses.com/community/flagstaff-country-club-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-downtown-flagstaff',
                  data: 'Downtown Flagstaff',
                  url: 'https://www.flagstaffhouses.com/community/downtown-flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-flagstaff',
                  data: 'Flagstaff',
                  url: 'https://www.flagstaffhouses.com/community/flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-flagstaff-ranch',
                  data: 'Flagstaff Ranch',
                  url: 'https://www.flagstaffhouses.com/community/flagstaff-ranch-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-linwood-heights',
                  data: 'Linwood Heights',
                  url: 'https://www.flagstaffhouses.com/community/linwood-heights-flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-lockett-ranches',
                  data: 'Lockett Ranches',
                  url: 'https://www.flagstaffhouses.com/community/lockett-ranches-flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-pine-canyon',
                  data: 'Pine Canyon',
                  url: 'https://www.flagstaffhouses.com/community/pine-canyon-flagstaff-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-ponderosa-trails',
                  data: 'Ponderosa Trails',
                  url: 'https://www.flagstaffhouses.com/community/ponderosa-trails-az-real-estate/',
                },
                {
                  id: 'desktop-submenu-item-university-heights-highlands',
                  data: 'University Heights/ Highlands',
                  url: 'https://www.flagstaffhouses.com/community/university-heights-highlands-az-real-estate/',
                },
              ],
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-6',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-1-col-6-heading',
              className: '',
              type: 'heading',
              elementType: 'h1',
              data: 'Get In Touch',
            },
            {
              id: 'f-1-col-6-socials',
              className: 'on-lopud-footer-socials',
              type: 'socialIcons',
              itemClassName: 'on-lopud-footer-social-item',
              items: [
                {
                  id: 'footer-zillow',
                  className: '',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/lopud/zillow-footer.png',
                  url: 'https://www.zillow.com/profile/flagstaffhouses/',
                },
                {
                  id: 'footer-facebook',
                  className: 'on-lopud-footer-fb',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/lopud/fb-footer.jpg',
                  url: 'https://www.facebook.com/FlagstaffTopProducersRealEstate',
                },
                {
                  id: 'footer-instagram',
                  className: '',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/lopud/instagram-footer.png',
                  url: 'https://www.instagram.com/flagstaffhouses/',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-lopud-footer-two',
      className: 'on-lopud-footer-section on-lopud-footer-section-last',
      sectionClassName: 'on-lopud-footer-section-container on-lopud-footer-section-container-last',
      columns: [
        {
          id: 'on-lopud-f-1-col-1',
          className: 'col-lg-2',
          items: [
            {
              id: 'f-2-col-1-image',
              className: 'on-lopud-footer-logo',
              type: 'image',
              url: 'https://si-homelight.s3.amazonaws.com/sites/lopud/logo-footer.png',
              style: {},
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-1',
          className: 'col-lg-4 on-lopud-info-column',
          items: [
            {
              id: 'f-2-col-2-heading',
              type: 'heading',
              elementType: 'h2',
              data: 'Flagstaff Top Producers Real Estate',
            },
            {
              id: 'f-2-col-2-address-one',
              type: 'heading',
              elementType: 'p',
              data: '323 S River Run Road, Suite 2',
            },
            {
              id: 'f-2-col-2-address-two',
              type: 'heading',
              elementType: 'p',
              data: 'Flagstaff, AZ 86001',
            },
            {
              id: 'f-2-col-2-address-phone',
              type: 'heading',
              elementType: 'p',
              data: 'Phone: (928) 214-7100',
            },
          ],
        },
        {
          id: 'on-lopud-f-1-col-1',
          className: 'col-lg-6 on-lopud-terms-column',
          items: [
            {
              id: 'f-2-col-3-realtor-logo',
              type: 'image',
              style: { width: '100px' },
              url: 'https://si-homelight.s3.amazonaws.com/sites/lopud/logo-footer-equal-realtor.png',
            },
            {
              id: 'f-2-col-3-menu',
              type: 'menu',
              className: 'on-lopud-privacy-menu',
              itemClassName: 'on-lopud-privacy-menu-item',
              items: [
                {
                  id: 'footer-terms-of-services',
                  data: 'Terms Of Services',
                  url: 'https://www.flagstaffhouses.com/terms-of-services',
                },
                {
                  id: 'footer-privacy-policy',
                  data: 'Privacy Policy',
                  url: 'https://www.flagstaffhouses.com/privacy-policy',
                },
                {
                  id: 'footer-sitemap',
                  data: 'Sitemap',
                  url: 'https://www.flagstaffhouses.com/sitemap_index.xml',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Flagstaff Top Producers Real Estate Team',
    agentName: 'Valerie Caro',
    agentDescription: 'Top agents in Flagstaff, AZ',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/lopud/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/lopud/hero-bg.jpg',
  },
};
